

// import Default from './_app/Template.es6'; //「default」で指定した変数を取得(変数名は自由)
// import {
//     Template,       //変数名を指定して取得(変数名は固定)
//     TemplateValue1, //変数名を指定して取得(変数名は固定)
//     TemplateValue2, //変数名を指定して取得(変数名は固定)
// } from './_app/Template.es6';
// import * as All from './_sample/Template.es6'; //全ての変数を含んだオブジェクトを取得(変数名は自由)
// import {Default} from './_app/Template.es6'; //「default」で指定した変数を取得(変数名は自由)
// import {Fit} from './_app/xflag/fit.js'; //「default」で指定した変数を取得(変数名は自由)
import {Tween, Call} from './_index/xflag/tween.js';
import {AnchorScroll} from './_index/xflag/anchor.js';
import {Accordion} from './_index/xflag/accordion.js';
// import {Tracking} from './_app/xflag/tracking.js';
// import {ListSection} from './_app/ListSection.es6';
// import {TalkSection} from './_app/TalkSection.es6';
// import {Header} from './_app/Header.es6';

export class App {
	constructor() {

		let x_tab = document.getElementsByClassName("x-tab");
		for( let i = 0 ; i < x_tab.length ; i++ ){
			new TabContent(x_tab[i]);
		}
	}

}

class TabContent{
	constructor( rootDOM ){
		
		this.switch = rootDOM.getElementsByClassName("x-tab-switch")[0];
		this.content = rootDOM.getElementsByClassName("x-tab-content")[0];
		this.switch.li = this.switch.getElementsByTagName("li");
		this.content.li = this.content.getElementsByTagName("li");

		this.focusIndex = 0;
		this.switch.li[this.focusIndex].setAttribute("data-is-focus", "");
		this.content.li[this.focusIndex].setAttribute("data-is-focus", "");
		
		this.switch.onClick	= (evt)=>{
			this.switch.li[this.focusIndex].removeAttribute("data-is-focus");
			this.content.li[this.focusIndex].removeAttribute("data-is-focus");
			this.focusIndex = evt.currentTarget.index;
			this.switch.li[this.focusIndex].setAttribute("data-is-focus", "");
			this.content.li[this.focusIndex].setAttribute("data-is-focus", "");
		}
		for( let i = 0 ; i < this.switch.li.length ; i++ ){
			this.switch.li[i].index = i;
			this.switch.li[i].addEventListener("click", this.switch.onClick);
		}
	}
}

//------------------------------------------------------------------------------------------------------------------------------


(function( ){

	window.requestAnimationFrame = 	window.requestAnimationFrame ||
									window.webkitRequestAnimationFrame ||
									function( func ){ return setTimeout( func, 1000/60 ); };

	window.cancelAnimationFrame = 	window.cancelAnimationFrame ||
									window.webkitCancelAnimationFrame ||
									function( id ){ clearTimeout( id ) ; };

	document.readyState === "complete"
		? setTimeout( function(){ new App( ); }, 1 )
		: document.addEventListener( "DOMContentLoaded", function(){ new App( ); } );

} )( ) ;


//------------------------------------------------------------------------------------------------------------------------------

