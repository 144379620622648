

import {Tween, Call} from './tween.js';

export let Accordion = {};
let Fit = {fit:()=>{}};

(function( ){


//------------------------------------------------------------------------------------------------------------------------------


	var Accordion = function( )
	{
		window.Accordion = Accordion ;

		var _this = this , i , temp ;

		//

		var accordion = document.getElementsByClassName( "js-x-accordion" ) ;

		for( i = 0 ; i < accordion.length ; i++ )
		{
			new Accordion.Object( accordion[i] ) ;
		}

	}
	Accordion.Object = function( trigger )
	{
		var _this = this , i , temp ;

		//

		var targetClass = trigger.getAttribute( "data-accordion-id" ) ;
		var target = trigger.nextElementSibling ;
		target.style.overflow = "hidden" ;

		Accordion.instance[ targetClass ] = this ;

		var isOpened = trigger.getAttribute( "data-is-opened" ) == undefined ? false : true ;
		target.style.height = isOpened ? "" : "0px" ;
		trigger.className = isOpened ? trigger.className + " opened" : trigger.className ;


		var onMouseEvent = function( evt )
		{
			switch( evt.type )
			{
				case "click" :
					isOpened = !isOpened ;

					isOpened ? _this.open( ) : _this.close( ) ;
					break ;
			}
		}

		_this.open = function( isSudden )
		{
			isOpened = true  ;

			var prevHeight = target.style.height ;
			target.style.height = "" ;
			var h = target.offsetHeight ;
			target.style.height = prevHeight ;

			trigger.className = trigger.className + " opened" ;

			Tween.remove( target.style ) ;

			Tween.add( target.style , { height:h+"px" , time:isSudden?0:0.5 , transition:"easeInOutQuart" } ,
				{ 	onUpdate:function(){Fit.fit()} ,
					onComplete:function(){target.style.height="";Fit.fit();} } ) ;
		}
		_this.close = function( isSudden )
		{
			isOpened = false  ;

			trigger.className = trigger.className.replace( / opened/ , "" ) ;

			Tween.remove( target.style ) ;
			var h = target.offsetHeight ;
			target.style.height = h+"px" ;

			Tween.add( target.style , { height:"0px" , time:isSudden?0:0.5 , transition:"easeInOutQuart" } ,
				{	onUpdate:function(){Fit.fit()} , onComplete:function(){Fit.fit()} } ) ;
		}


		trigger.addEventListener( "click" , onMouseEvent ) ;
	}
	Accordion.instance = { } ;


//------------------------------------------------------------------------------------------------------------------------------


	var _requestAnimationFrame = 	window.requestAnimationFrame ||
									window.webkitRequestAnimationFrame ||
									window.mozRequestAnimationFrame	||
									window.oRequestAnimationFrame ||
									window.msRequestAnimationFrame ||
									function( func ){ return setTimeout( func , 1000/60 ) ; } ;


	var _cancelAnimationFrame = 	window.cancelAnimationFrame ||
									window.webkitCancelAnimationFrame ||
									window.mozCancelAnimationFrame	||
									window.oCancelAnimationFrame ||
									window.msCancelAnimationFrame ||
									function( id ){ clearTimeout( id ) ; } ;

	// DOMの準備が完了したら、呼ぶ
	document.readyState === "complete"
		? setTimeout( Accordion , 1 )
		: document.addEventListener( "DOMContentLoaded", Accordion ) ;


//-------------------------------------------------------------------------------------------------------------------------------


} )( ) ;

