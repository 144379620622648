/**
 * 共通ページ内アンカー処理
 *  - Tween.jsに依存
 */

import {Tween, Call} from './tween.js';
let AnchorScroll;

(function( ){

	/**
	 * 共通ページ内アンカー処理
	 * @method AnchorScroll
	 */
	AnchorScroll = function() {

		AnchorScroll.add() ;
	}

	/**
	 * 指定要素のページ上部からの位置を取得する処理
	 * @param  {Object} target     アンカー対象 HTML OBJECT
	 * @param  {Object} rootTarget スクロールの基準場所、指定しなければdocument
	 * @return {Number}            スクロール位置
	 */
	AnchorScroll.onAnchorClick = function( evt ) {
		evt.preventDefault( ) ;

		//

		var hash = evt.currentTarget.href.split( "#" )[1].split( "?" )[0] ;
		var target ;

		if( hash != "" ) {
			target = document.getElementById( hash ) ;
			target.id = "" ;
			location.href = "#" + hash ;
			target.id = hash ;
		}

		AnchorScroll.to( target ) ;
	}


	AnchorScroll.add = function( target ) {
		if( target ) {
			AnchorScroll.add.check(target) ;
		}else {
			var a = document.getElementsByTagName( "a" ) ;
			for( var i = 0 ; i < a.length ; i++ ) {
				AnchorScroll.add.check(a[i]) ;
			}
		}
	}
	AnchorScroll.add.check = function( target ) {
		if( AnchorScroll.add.instance.indexOf(target) != -1 ) {
			return ;
		}

		if( /#/.test( target.href ) ) {
			var hrefSplit = target.href.split("#")[0].split( "/" ) ;
			if( hrefSplit[hrefSplit.length-1] == "" ) {
				hrefSplit[hrefSplit.length-1] = "index.html" ;
			}

			var currentHrefSplit = location.href.split("#")[0].split( "/" ) ;
			if( currentHrefSplit[hrefSplit.length-1] == "" ) {
				currentHrefSplit[hrefSplit.length-1] = "index.html" ;
			}

			if( hrefSplit[hrefSplit.length-1] != currentHrefSplit[hrefSplit.length-1] || hrefSplit[hrefSplit.length-2] != currentHrefSplit[hrefSplit.length-2] ) {
				return ;
			}

			if( target.hasAttribute( "data-no-scroll" ) ) {
				return ;
			}

			AnchorScroll.add.instance.push( target ) ;
			target.addEventListener( "click" , AnchorScroll.onAnchorClick ) ;
		}
	}
	AnchorScroll.add.instance = [] ;


	AnchorScroll.to = function( target , time , transition , delay ) {

		var time = time == undefined ? 0.8 : time ;
		var transition = transition == undefined ? "easeInOutQuint" : transition ;

		var top = target ? getOffsetTop( target ) : 0 ;

		if( window.Fit && window.Fit.zoomScale ) {
			top = top * window.Fit.zoomScale ;
		}

		Tween.scroll( top , time , transition , delay ) ;
	}

	AnchorScroll.to.sudden = function( target ) {

		var top = target ? getOffsetTop( target ) : 0 ;

		if( window.Fit && window.Fit.zoomScale ) {
			top = top * window.Fit.zoomScale ;
		}

		window.scrollTo( window.pageYOffset , top ) ;
	}


	/**
	 * 指定要素のページ上部からの位置を取得する処理
	 * @param  {Object} target     アンカー対象 HTML OBJECT
	 * @param  {Object} rootTarget スクロールの基準場所、指定しなければdocument
	 * @return {Number}            スクロール位置
	 */
	var getOffsetTop = AnchorScroll.getOffsetTop = function( target , rootTarget ) {

		rootTarget = rootTarget == undefined ? document : rootTarget ;

		var targetY = target.offsetTop ;
		var parent = target.offsetParent ;
		while( parent && parent != document.body ){

			targetY += parent.offsetTop ;
			parent = parent.offsetParent ;
		}

		return targetY ;
	}


//------------------------------------------------------------------------------------------------------------------------------



	// DOMの準備が完了したら、呼ぶ
	document.readyState === "complete"
		? setTimeout( function(){ new AnchorScroll( ); } , 1 )
		: document.addEventListener( "DOMContentLoaded", function(){ new AnchorScroll( ); } ) ;



//------------------------------------------------------------------------------------------------------------------------------


} )( ) ;
